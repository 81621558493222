import React, { useState } from "react";
import emailjs from "emailjs-com";
import blueBG from "../assets/banner-blue.jpg";
import "./Contact.scss"; // Import the SCSS

const EmailForm = () => {
	const [formData, setFormData] = useState({
		to_name: "DevatCode",
		name: "",
		email: "",
		message: "",
	});

	const [successMessage, setSuccessMessage] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		const serviceID = process.env.REACT_APP_SERVICE_ID;
		const templateID = process.env.REACT_APP_TEMPLATE_ID;
		const userID = process.env.REACT_APP_USER_ID;

		e.preventDefault();

		emailjs
			.send(
				serviceID, // EmailJS service ID
				templateID, // EmailJS template ID
				formData,
				userID // EmailJS public API key
			)
			.then(
				(result) => {
					setSuccessMessage("Message sent successfully!");
				},
				(error) => {
					setSuccessMessage("Failed to send message. Try again later.");
				}
			);

		// Clear the form after submission
		setFormData({
			to_name: "DevatCode",
			name: "",
			email: "",
			message: "",
		});
	};

	return (
		<div className="contact">
			<img src={blueBG} alt="blue bg" />
			<div className="email-form-container">
				<h2>Drop us a line... Let's talk!</h2>
				<form onSubmit={handleSubmit}>
					<label>Name:</label>
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						required
					/>

					<label>Email:</label>
					<input
						type="email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						required
					/>

					<label>Message:</label>
					<textarea
						name="message"
						value={formData.message}
						onChange={handleChange}
						required
					/>

					<button type="submit">Send</button>
					{successMessage && <p>{successMessage}</p>}
				</form>
			</div>
		</div>
	);
};

export default EmailForm;

// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import EmailForm from "./pages/EmailForm";
import Training from "./components/Training";

import Footer from "./components/Footer";
import "./App.scss";

import Navbar from "./components/Navbar";
import "./components/navbar.scss";

function App() {
	return (
		<Router>
			<div className="app">
				<main>
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/pricing" element={<Portfolio />} />
						<Route path="/contact" element={<EmailForm />} />
						<Route path="/training" element={<Training />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</Router>
	);
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import "./training.css";
import Type from "../assets/type.jpg";
import LottaCode from "../assets/lotta-code.jpg";

const Training = () => {
	return (
		<div className="training">
			<div className="left">
				<h1>Work</h1>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias,
					esse eum! Veniam beatae commodi excepturi corrupti asperiores eius
					voluptas eveniet, saepe ipsum? Nisi ipsam illum optio maiores
					perferendis. Magni, consequuntur!
				</p>
				<Link to="/contact">
					<button className="btn">Contact</button>
				</Link>
			</div>
			<div className="right">
				<div className="img-container">
					<div className="image-stack top">
						<img src={Type} className="img" alt="" />
					</div>
					<div className="image-stack bottom">
						<img src={LottaCode} className="img" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Training;

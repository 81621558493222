// src/pages/Portfolio.js
import React from "react";
import { Link } from "react-router-dom";
import amps from "../assets/banner-green.jpg";
import "./Portfolio.scss";

const Portfolio = () => {
	return (
		<div className="pricing">
			<img className="ampsbg" src={amps} alt="amps pic" />
			<div className="card-container">
				<div className="card">
					<span className="bar"></span>
					<span className="btc">The Basics</span>
					<h2> Basic Plan: Starter Website Ideal for:</h2>
					<div className="features">
						Individuals, startups, or small businesses looking for a simple yet
						professional web presence.
						<ul>
							<li>
								Responsive Website: Design and development of a responsive,
								single-page or basic multi-page website using Webflow.
							</li>
							<li>UI/UX Design: Basic visual design using Figma.</li>
							<li>
								Frontend Development: Basic interactivity and structure using
								React.js.
							</li>
							<li>
								Form Integration: Simple form handling (e.g., contact forms) and
								validation.
							</li>
							<li>
								SEO Optimization: Basic SEO setup (meta tags, description, and
								keywords).
							</li>
							<li>
								Deployment: Host the website using Webflow's hosting solution or
								any other basic hosting plan.
							</li>
						</ul>
						Deliverables: Fully functional and mobile-responsive website. Basic
						Figma design and prototype. Web hosting setup and support.
					</div>
					<Link to="/contact" className="btn">
						Let's talk
					</Link>
				</div>
				<div className="card">
					<span className="bar"></span>
					<span className="btc">The Bells</span>
					<h2>Standard Plan: Business Web Application Ideal for:</h2>
					<div className="features">
						Small to mid-sized businesses looking for dynamic features like
						databases, user authentication, and backend functionality.
						<ul>
							<li>
								Custom Web App: Build a full web application with user
								authentication, dashboard, and other business functionalities
								using React.js for frontend and Node.js for backend.
							</li>
							<li>
								Database Integration: Use MongoDB for managing customer data,
								products, or any other dynamic content.
							</li>
							<li>
								API Integration: RESTful API for data communication between
								frontend and backend.
							</li>
							<li>
								UI/UX Design: Tailored Figma designs based on client
								requirements.
							</li>
							<li>
								Frontend Development: Complex state management, component-based
								architecture, and routing using React.
							</li>
							<li>
								Backend Development: Create APIs, manage data storage, user
								authentication using Node.js and MongoDB.
							</li>
							<li>
								Deployment & Hosting: Deployment on services like Heroku,
								Netlify, or Vercel for frontend, and MongoDB Atlas for database
								management.
							</li>
						</ul>
						<ul>
							<li>Deliverables: Fully functional business web application.</li>
							<li>Responsive, dynamic frontend and backend integration.</li>
							<li>Secure user authentication and database management.</li>
							<li>Basic documentation and user onboarding.</li>
						</ul>
					</div>

					<Link to="/contact" className="btn">
						Let's talk
					</Link>
				</div>
				<div className="card">
					<span className="bar"></span>
					<span className="btc">The Whistles</span>
					<h2>Premium Plan: Full-Featured Custom Platform Ideal for:</h2>
					<div className="features">
						Established businesses and enterprises looking for a full-featured
						platform with scalability, design systems, and advanced features.
						<ul>
							<li>
								Custom Platform Development:Build a full-scale, complex
								application tailored to the client's business model, including
								multi-user roles, payment gateways, and third-party service
								integrations.
							</li>
							<li>
								End-to-End Solution: Full-stack development using React.js,
								Node.js, MongoDB, and other advanced technologies.
							</li>
							<li>
								Advanced UI/UX Design: Comprehensive, multi-page design with
								advanced user flows and micro-interactions using Figma.
							</li>
							<li>
								Backend Architecture: Robust server-side logic, cloud functions,
								data management, and API development using Node.js.
							</li>
							<li>
								Database Management: Use MongoDB for large-scale, scalable data
								storage, including real-time data sync, caching, and advanced
								search functionalities.
							</li>
							<li>
								Advanced Features: Payment gateway integration, role-based
								access control, advanced admin panel, and reporting dashboards.
							</li>
							<li>
								Custom Animations: Use Webflow or React-based libraries for
								advanced animations and interactions.
							</li>
							<li>
								Security & Performance: Ensure data security, application
								scalability, and optimal performance for high-traffic
								applications.
							</li>
							<li>
								Ongoing Support: Maintenance, updates, and support
								post-deployment.
							</li>
						</ul>
						<ul>
							<li>
								Deliverables: Enterprise-level web platform with a custom design
								system.
							</li>
							<li>
								Multi-tier architecture with full backend and frontend
								integration.
							</li>
							<li>Scalable database and security measures in place.</li>
							<li>Detailed documentation and training.</li>
						</ul>
					</div>

					<Link to="/contact" className="btn">
						Let's talk
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Portfolio;

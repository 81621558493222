import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import "./navbar.scss";

const Navbar = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => {
		setClick(!click);
	};

	return (
		<div className="header">
			<Link to="/">
				<h1>{isMobile ? "{ }" : "Developer at Code {"}</h1>
			</Link>
			<ul className={click ? "nav-menu active" : "nav-menu"}>
				<li className="nav-btns" onClick={handleClick}>
					<Link to="/">Home </Link>
				</li>
				<li className="nav-btns" onClick={handleClick}>
					<Link to="/pricing">Services</Link>
				</li>
				{/* <li className="nav-btns" onClick={handleClick}>
					<Link to="/training">Work</Link>
				</li> */}
				<li className="nav-btns" onClick={handleClick}>
					<Link to="/contact">Contact</Link>
				</li>
			</ul>
			<div className="hamburger" onClick={handleClick}>
				{click ? (
					<FaTimes size={20} style={{ color: "#fff" }} />
				) : (
					<FaBars size={20} style={{ color: "#fff" }} />
				)}
			</div>
		</div>
	);
};

export default Navbar;

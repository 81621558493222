// src/pages/Home.js
import React from "react";
import { Link } from "react-router-dom";

import redBG from "../assets/banner-red.jpg";
import "./Home.scss";

const Home = () => {
	return (
		<section className="home">
			<div className="hero">
				<img className="mobilebg" src={redBG} alt="mobile bg" />
				<div className="content">
					<h1 className="heading-secondary">Developer at Code</h1>
					<p>It's what we do...</p>
					<div>
						<Link to="/pricing" className="btn">
							Services
						</Link>
						<Link to="/contact" className="btn btn-light">
							Contact
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Home;
//changes

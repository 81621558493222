import React from "react";
import "./Footer.scss";
import {
	FaFacebook,
	FaLinkedin,
	FaMailBulk,
	//FaPhone,
	FaSearchLocation,
	FaTwitter,
} from "react-icons/fa";

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer-container">
				<div className="left">
					<div className="location">
						<FaSearchLocation
							size={20}
							style={{ color: "white", marginRight: "2rem" }}
						/>

						<div>
							<p>Codes in</p>
							<h4>Utah, USA</h4>
						</div>
					</div>
					{/* <div className="phone">
						<h4>
							<FaPhone
								size={20}
								style={{ color: "white", marginRight: "2rem" }}
							/>
							1-800-555-1234
						</h4>
					</div> */}
					<div className="email">
						<h4>
							<FaMailBulk
								size={20}
								style={{ color: "white", marginRight: "2rem" }}
							/>
							dev@developeratcode.com
						</h4>
					</div>
				</div>
				<div className="right">
					<h4>About DAC</h4>
					<p>World traveled solution provider.</p>
					<div className="social">
						<FaFacebook
							size={30}
							style={{ color: "white", marginRight: "1rem" }}
						/>
						<FaTwitter
							size={30}
							style={{ color: "white", marginRight: "1rem" }}
						/>
						<FaLinkedin
							size={30}
							style={{ color: "white", marginRight: "1rem" }}
						/>
					</div>
					<div>
						&copy;
						<span id="copyright"></span>
						DeveloperatCode
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
